import React, {useCallback, useEffect, useState} from 'react';
import {Table, Select} from 'antd';
import styled from 'styled-components';
import {errorHandler} from '../errors';
import {useOutlet} from 'reconnect.js';
import {Link} from 'gatsby';
import Tag from '../Widgets/Tag';
import {PERIOD_STATUS} from '../dictionary';
import PeriodUtil from '../Utils/PeriodUtil';
import Block from '../Widgets/Block';
import Button from '../Widgets/Button';
import {Refresh} from '@styled-icons/boxicons-regular/Refresh';
const appConfig = require('../data.json');

const PAGINATION_INIT = {
  current: 1,
  pageSize: 20,
};

export default function PeriodListPage(props) {
  const [records, setRecords] = useState([]);
  const [total, setTotal] = useState(0);
  const [actions] = useOutlet('actions');
  const [filters, setFilters] = useState({
    status: '',
    ...PAGINATION_INIT,
  });

  const changeFilters = (obj) => {
    setFilters((prev) => ({...prev, ...PAGINATION_INIT, ...obj}));
  };

  const getRecords = useCallback(async () => {
    actions.setLoading(true);
    try {
      let resp = await actions.getPeriods({
        offset: (filters.current - 1) * filters.pageSize,
        limit: filters.pageSize,
        ordering: '-created',
        status: filters.status,
      });
      setRecords(resp.results);
      setTotal(resp.count);
    } catch (err) {
      errorHandler(err, '取得委託單錯誤');
    }
    actions.setLoading(false);
  }, [filters]);

  useEffect(() => {
    getRecords();
  }, [getRecords]);

  return (
    <Wrapper>
      <Block style={{marginBottom: 20}}>
        <div className="row">
          <h4>委託單狀態</h4>
          <Select
            value={filters.status}
            onChange={(value) => changeFilters({status: value})}>
            <Select.Option value={''}>全部</Select.Option>
            {Object.keys(PERIOD_STATUS).map((key) => (
              <Select.Option value={key} key={key}>
                {PERIOD_STATUS[key]}
              </Select.Option>
            ))}
          </Select>
          <div style={{flex: 1}}></div>
          <Button
            type="primary"
            onClick={getRecords}
            icon={<Refresh color="#fff" size={20} style={{marginRight: 5}} />}>
            刷新
          </Button>
        </div>
      </Block>

      <Table
        columns={[
          {
            title: '編號',
            key: 'id',
            dataIndex: 'id',
            render: (id) => <Link to={`/period/?id=${id}`}>{id}</Link>,
          },
          {
            title: '委託單狀態',
            key: 'status',
            dataIndex: 'status',
            render: (value) => (
              <Tag color={PeriodUtil.getStatusColor(value)}>
                {PERIOD_STATUS[value]}
              </Tag>
            ),
          },
          {
            title: '藍新開立狀態',
            key: 'status',
            render: (record) => {
              const detail = JSON.parse(record.payment_transaction_detail);
              return detail?.Status === 'SUCCESS' ? '成功' : '錯誤';
            },
          },
          {
            title: '期數',
            key: 'times',
            render: (record) => record.times + ' / ' + record.auth_times,
          },
          {title: '每期金額', key: 'period_amount', dataIndex: 'period_amount'},
          {title: '總金額', key: 'amount', dataIndex: 'amount'},
          {
            title: '顧客姓名',
            key: 'buyer',
            dataIndex: 'buyer',
            render: (buyer) => (
              <Link to={`/member/?id=${buyer.id}`}>{buyer.name}</Link>
            ),
          },

          {
            title: '建立時間',
            key: 'created',
            dataIndex: 'created',
            render: (created) => created.slice(0, 10),
          },
        ]}
        dataSource={records}
        rowKey={'id'}
        pagination={{
          pageSize: filters.pageSize,
          current: filters.current,
          total,
        }}
        onChange={(pagination) =>
          changeFilters({
            pageSize: pagination.pageSize,
            current: pagination.current,
          })
        }
        // scroll={{x: 1300}}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f2f2f2;
  padding: 20px;
`;
