const appConfig = require('../data.json');

function getStatusColor(value) {
  if (value === 'review_waiting' || value === 'extra_payment_waiting') {
    return appConfig.colors.error;
  }
  if (value === 'terminate') {
    return '#999';
  }
  return '#4BAA70';
}

export default {
  getStatusColor,
};
